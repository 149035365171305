import React, { Component } from 'react'
import { Layout } from 'core/components'
import { IndexLayout } from 'modules'
import { AAnimate } from 'shared/components/common'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import avisoccp from 'img/Banner_web.jpg'
import { navigate } from 'gatsby'

type IndexProps = PageProps

interface IndexState {
  mounted: boolean
  collapse: boolean
}

React.createElement('div')

class Index extends Component<IndexProps, IndexState> {
  state = {
    mounted: false,
    collapse: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true })
    }, 0)
    setTimeout(() => {
      this.setState({ collapse: true })
    }, 1000)

    // Obtener parámetro 'code' de la URL
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')

    // Navegar a la página de 'Rastreo' si se encuentra el parámetro 'code' en la URL
    if (code) {
      navigate('/rastreo', { state: { code } })
    }
  }
  toggle = () => {
    this.setState(state => ({ collapse: !state.collapse }))
  }
  render() {
    const {
      pageContext: { locale }
    } = this.props
    const { mounted } = this.state
    return (
      <Layout locale={locale}>
        <Modal isOpen={this.state.collapse} size="lg" toggle={this.toggle}>
          <ModalHeader toggle={this.toggle} />
          <ModalBody>
            <img style={{ width: '100%', height: '100%', borderRadius: '10px' }} src={avisoccp} className="img-responsive" />
          </ModalBody>
        </Modal>

        <AAnimate type="fadeIn" in={mounted}>
          <IndexLayout />
        </AAnimate>
      </Layout>
    )
  }
}

export default Index
